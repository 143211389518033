import fondo from '../images/fondo.webp'
import fondo1 from '../images/fondo1.webp'
import fondo2 from '../images/fondo2.webp'
import fondo3 from '../images/fondo3.webp'
import fondo4 from '../images/fondo4.webp'
import fondo5 from '../images/fondo5.webp'
import fondo6 from '../images/fondo6.webp'
import fondo7 from '../images/fondo7.webp'
import fondo8 from '../images/fondo8.webp'
import fondo9 from '../images/fondo9.webp'
import fondo10 from '../images/fondo10.webp'
import fondo11 from '../images/fondo11.webp'
import fondo12 from '../images/fondo12.webp'
import fondo13 from '../images/fondo13.webp'
import fondo14 from '../images/fondo14.webp'
import fondo15 from '../images/fondo15.webp'
import fondo16 from '../images/fondo16.webp'
import fondo17 from '../images/fondo17.webp'
import fondo18 from '../images/fondo18.webp'
import fondo19 from '../images/fondo19.webp'
import fondo20 from '../images/fondo20.webp'

const Imagenes = [
    {imagen:`${fondo}`,titulo:'Casa 1', texto:'lalalal' },
    {imagen:`${fondo1}`,titulo:'Casa 2', texto:'lalalal' },
    {imagen:`${fondo2}`,titulo:'Casa 3', texto:'lalalal' },
    {imagen:`${fondo3}`,titulo:'Casa 4', texto:'lalalal' },
    {imagen:`${fondo4}`,titulo:'Casa 5', texto:'lalalal' },
    {imagen:`${fondo5}`,titulo:'Casa 6', texto:'lalalal' },
    {imagen:`${fondo6}`,titulo:'Casa 7', texto:'lalalal' },
    {imagen:`${fondo7}`,titulo:'Casa 8', texto:'lalalal' },
    {imagen:`${fondo8}`,titulo:'Casa 9', texto:'lalalal' },
    {imagen:`${fondo9}`,titulo:'Casa 10', texto:'lalalal' },
    {imagen:`${fondo10}`,titulo:'Casa 11', texto:'lalalal' },
    {imagen:`${fondo11}`,titulo:'Casa 12', texto:'lalalal' },
    {imagen:`${fondo12}`,titulo:'Casa 13', texto:'lalalal' },
    {imagen:`${fondo13}`,titulo:'Casa 14', texto:'lalalal' },
    {imagen:`${fondo14}`,titulo:'Casa 15', texto:'lalalal' },
    {imagen:`${fondo15}`,titulo:'Casa 16', texto:'lalalal' },
    {imagen:`${fondo16}`,titulo:'Casa 17', texto:'lalalal' },
    {imagen:`${fondo17}`,titulo:'Casa 18', texto:'lalalal' },
    {imagen:`${fondo18}`,titulo:'Casa 19', texto:'lalalal' },
    {imagen:`${fondo19}`,titulo:'Casa 20', texto:'lalalal' },
    {imagen:`${fondo20}`,titulo:'Casa 21', texto:'lalalal' },
    
]

export default Imagenes