import React from 'react'
import Pvc from '../exports/Pvc';

const Card = () => {
  return (
    <div className='flex flex-col justify-center mx-5 space-y-5 mt-12 md:space-y-0 md:flex-row   md:space-x-20' >
        {Pvc.map (image => (
            <div className="max-w-sm bg-white border border-gray-200 rounded-lg shadow transition ease-in duration-500 hover:ease-out hover:duration-700 hover:scale-110 ">
                <a>
                    <img className="rounded-t-lg" src={image.imagen} alt="" />
                </a>
                <div className="p-5">
                    <a>
                        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 ">{image.titulo}</h5>
                    </a>
                    <p className="mb-3 font-normal text-gray-700 ">{image.texto}<a className='text-blue-700' href={image.link} target="_blank"  rel="noreferrer" >Leer mas</a></p>
                </div>
            </div>
        ))}
    </div>
  )
}

export default Card