import React, { useState,  useEffect } from 'react'
import Button from './Button';
import { Link } from "react-scroll";
import Logo from '../icons/carpyvent.webp'

const Navbar = () => {
  let Links =[
    {name:"Inicio",section:"section1"},
    {name:"¿Quienes somos?",section:"section2"},
    {name:"Nuestra Historia",section:"section3"},
    {name:"PVC",section:"section4"},
    {name:"Galeria",section:"section5"},
  ];
  let [open,setOpen]=useState(false);
  
  const [offset, setOffset] = useState(0);
    useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
    }, []);
 
  return (
    <div className={`shadow-md w-full fixed top-0 left-0 transition-all duration-500 ease-in-out ${offset<475 ? 'top-[-490px]':'top-0'}`}>
      <div className='md:flex items-center justify-between bg-white py-5 md:px-10 px-7'>
        <div className='font-bold text-2xl cursor-pointer flex items-center font-[Poppins] 
        text-gray-800'>
          <Link activeClass="active"
                to={"section1"}
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}>
                <img src={Logo} alt="logo" className='md:h-14 h-12 '/>
          </Link>
        </div>  
        <div onClick={()=>setOpen(!open)} className='text-3xl absolute right-8 top-6 cursor-pointer md:hidden '>
          <ion-icon name={open ? 'close':'menu'} />
        </div>
        <ul className={`md:flex md:items-center md:pb-0 pb-8 absolute md:static bg-white md:z-auto z-[-1] left-0 w-full md:w-auto md:pl-0  pl-9 transition-all duration-500 ease-in ${open ? 'top-20':'top-[-490px]'}`}>
          {
            Links.map((link)=>(
              <li key={link.name} className='md:ml-8 text-xl md:my-0 my-7 cursor-pointer'>
                <Link
                activeClass="active"
                to={link.section}
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                onClick={()=>setOpen(!open)} className='text-green-600 hover:text-emerald-400 duration-500'>
                  {link.name}
                </Link>
              </li>
            ))
          }
          <Link
          activeClass="active"
          to="section6"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}>
            <Button clickeado={()=>setOpen(!open)}>
              Cotiza aquí
            </Button>
          </Link>
        </ul>
      </div>
    </div>
  )
}

export default Navbar