import React from 'react'

const Footer = () => {
  return (
    <div className='flex py-6 mt-6 3xl:mt-56 justify-center text-[#efa229] border-t-2 border-gray-300 
    font-bold
    font-mono' >
        Copyright  Carpyvent © 2023  | Todos los derechos reservados
    </div>
  )
}

export default Footer