import React, { useState,  useEffect } from 'react'
import Whatsapp from "../icons/whatsappIcon.svg";
const WButton = () => {
    const [offset, setOffset] = useState(0);
        useEffect(() => {
        const onScroll = () => setOffset(window.pageYOffset);
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);
        }, []);

  return (
    <div className={``}>
            <a href="https://api.whatsapp.com/send?phone=56976785492&text= Hola, vengo de parte de la web y necesito ayuda" className={`fixed 
                w-12
                bottom-5
                md:w-14
                radius-[58px]
                z-50 
                transition-all 
                duration-500 
                ease-in-out
                hover:scale-125
                ${offset<475 ? 'right-[-490px]':'right-5' }`}  
                target="_blank"
                rel="noreferrer">
                    <img src={Whatsapp} alt="Whatsapp"/> 
            </a>
  </div>
  )
}

export default WButton