import React from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import Imagenes from '../exports/Images';
const responsiveSettings = [
    {
        breakpoint: 1400,
        settings: {
            slidesToShow: 3,
            slidesToScroll: 3
        }
    },
    {
      breakpoint: 800,
      settings: {
          slidesToShow: 2,
          slidesToScroll: 2
      }
  },
    {
        breakpoint: 300,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1
        }
    }
];

const Slider = () => {
    return (
        <div>
            <Slide slidesToScroll={2} slidesToShow={2} indicators={true} responsive={responsiveSettings}>
              
                 {Imagenes.map ( image => (
                  <div className='px-2' key={image.titulo}>
                    <div className=" rounded overflow-hidden shadow-lg">
                      <img className="pointer-events-none " src={image.imagen} alt="Sunset in the mountains"/>
                    </div>
                  </div>
                  ))}
            </Slide>
        </div>
    );
}

export default Slider