import dvp from '../images/dvp.webp'
import veratec from '../images/veratec.webp'



const Pvc = [
    {imagen:`${dvp}`,
    titulo:'Dvp', 
    texto:'Los marcos de pvc de la marca dvp contienen aislacion Certificada, aleta removible, materias primas de primer nivel...',
    link:'https://dvp.cl/wp-content/uploads/2020/03/CATALOGO-VENTANAS_PVC_2020.pdf'},
    {imagen:`${veratec}`,
    titulo:'Veratec', 
    texto:'Veratec se rige por la relacion precio-calidad, realizando productos de una calidad muy buena a un bajo costo... ',
    link:'https://www.grupohaffner.com/perfiles/'},
    
]

export default Pvc