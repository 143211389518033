import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";

const Result = () => {
  return (
    <p> Tu mensaje ha sido enviado con exito. Espero contactarte pronto.</p>
  );
};
const Contactanos = () => {
  const [result, showResult] = useState(false);
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_r5s746g",
        "template_9xkc5ar",
        form.current,
        "r4V9XqrAJ0OGfPRNE"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    form.current.reset();
    showResult(true);
  };
  return (
    <div className="h-full flex flex-col flex-grow text-white">
      <div className="grid max-w-6xl grid-cols-1  px-6 mx-auto md:pt-16 lg:px-8 md:grid-cols-2 md:divide-x ">
        <div className="py-6 md:py-0 md:px-6">
          <h1 className="text-4xl font-bold">Sigamos en contacto</h1>
          <p className="pt-2 pb-4">
            Rellena el formulario para pedir una cotización o tambien puedes
            llamarnos a los numeros certificados.
          </p>
          <div className="space-y-4">
            <p className="flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="w-5 h-5 mr-2 sm:mr-6 animate-bounce"
              >
                <path
                  fillRule="evenodd"
                  d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <a href="https://goo.gl/maps/TUEucE1MWiuY2kwZ9" target="_blank"  rel="noreferrer">
                Katalina Kleim - Osorno
              </a>
            </p>
            <p className="flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="w-5 h-5 mr-2 sm:mr-6 motion-safe:animate-pulse"
              >
                <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z"></path>
              </svg>
              <span>+56976785492</span>
            </p>
            <p className="flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="w-5 h-5 mr-2 sm:mr-6 motion-safe:animate-pulse"
              >
                <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z"></path>
              </svg>
              <span>+56997785681</span>
            </p>
            <p className="flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="w-5 h-5 mr-2 sm:mr-6 motion-safe:animate-pulse"
              >
                <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z"></path>
              </svg>
              <span>+56955891385</span>
            </p>
            <p className="flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="w-5 h-5 mr-2 sm:mr-6 "
              >
                <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
                <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
              </svg>
              <span>ventas@carpyventosorno.cl</span>
            </p>
            <p className="flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="w-5 h-5 mr-2 sm:mr-6 "
              >
                <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
                <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
              </svg>
              <span>carpyventspa005@gmail.cl</span>
            </p>
          </div>
        </div>
        <form
          ref={form}
          onSubmit={sendEmail}
          className="flex flex-col py-6 space-y-6 md:py-0 md:px-6 ng-untouched ng-pristine ng-valid"
        >
          <label className="block">
            <span className="mb-1">Nombre y apellído</span>
            <input
              type="text"
              name="fullname"
              placeholder="  Jose Martinez"
              className="block text-gray-900 py-2 w-full mt-1 rounded-md shadow-sm  bg-white"
              required
            />
          </label>
          <label className="block">
            <span className="mb-1">Correo electronico</span>
            <input
              type="email"
              name="email"
              placeholder="  jose@martinez.com"
              className="block text-gray-900 mt-1 py-2 w-full rounded-md shadow-sm  bg-white"
              required
            />
          </label>
          <label className="block">
            <span className="mb-1">Telefono</span>
            <input
              type="tel"
              pattern='^\+?569?[0-9]{8}$'
              name="phone"
              placeholder=" +56955891385"
              className="block text-gray-900 mt-1 py-2 w-full rounded-md shadow-sm  bg-white"
              required
            />
          </label>
          <label className="block">
            <span className="mb-1">Mensaje</span>
            <textarea
              name="message"
              rows="3"
              className="block text-gray-900 w-full  resize-none mt-1 rounded-md shadow-md  bg-white"
              required
            ></textarea>
          </label>
          <button
            className="self-center px-8 py-3 text-lg rounded-lg ease-in-out  bg-[#efa229] text-white 
            font-mono
          transition-all
          hover:scale-125 "
          >
            Enviar
          </button>
          <div className="row">{result ? <Result /> : null}</div>
        </form>
      </div>
      
    </div>
  );
};

export default Contactanos;
