import React from "react";
import { Link } from "react-scroll";
import { Fade, Slide, Roll } from "react-awesome-reveal";
import WButton from "./components/WButton";
import Navbar from "./components/Navbar";
import Slider from "./components/Slider";
import Card from "./components/Card";
import Flecha from "./icons/flecha.svg";
import Siegenia from "./icons/siegenia.png";
import Dvp from "./icons/dvp.png";
import Gu from "./icons/gu.png";
import Veratec from "./icons/veratec.png";
import Imagenes from "./exports/Images";
import Contactanos from "./components/Contactanos";
import Footer from "./components/Footer";
import inicio from "./video/inicio.mp4";


const App = () => {
  return (
    <div>
      <div>
        <WButton/>
        <section id="section1" className="h-screen ">
          <video
            src={inicio}
            autoPlay
            loop 
            muted
            alt="fondo"
            className="md:w-full h-full object-cover brightness-75 absolute"
          />
          <div className="flex flex-col items-center justify-center h-full top-0">
            <Fade duration={1000} direction={"down"}>
              <h1 className="text-white font-bold font-serif text-1xl min-[281px]:text-2xl  sm:text-5xl mt-40 ">
                CARPYVENT OSORNO
              </h1>
              <h1 className="text-white font-bold font-serif text-md min-[381px]:text-xl sm:text-3xl  ">
                PUERTAS Y VENTANAS DE PVC
              </h1>
            </Fade>
            <Fade duration={1000} direction={"up"} className="mt-40">
              <div>
                <Link
                  activeClass="active"
                  to="section2"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={1000}
                  className="cursor-pointer text-white font-bold text-2xl underline flex flex-col "
                >
                  <h1 className=" animate-pulse ">
                    Inicia tu tour
                  </h1>
                  <img
                    src={Flecha}
                    alt="flecha"
                    className="animate-bounce h-[40px] mt-10"
                  />
                </Link>
              </div>
            </Fade>
          </div>
        </section>        
        <section id="section2" className="bg-white h-screen">
          <div className="h-screen pt-14 px-4 grid lg:grid-cols-2 gap-2">
            <div>
              <Fade duration={1000} direction={"down"}>
                <h1
                  className="flex 
                                justify-center 
                                text-xl
                                min-[281px]:text-2xl  
                                sm:text-5xl
                                font-mono 
                                font-bold 
                                text-green-800 
                                align-center 
                                text-bold"
                >
                  Sobre Nosotros
                </h1>
              </Fade>
              <Fade duration={1000} direction={"left"}>
              <div>
              <p
                  className="mt-4 
                                md:mx-4  
                                text-sm 
                                3xl:mt-10
                                lg:text-lg
                                xl:text-xl
                                3xl:text-3xl
                                text-gray-800
                                font-mono
                                tracking-wide 
                                text-justify "
                >
                  Somos una empresa enfocada en la fabricación e
                  instalación de ventanas para hogares en la ciudad de Osorno.
                  Tambien estamos enfocados en el rubro de las ventas, ya que
                  estamos en constante busqueda de nuevos clientes, no solo de la ciudad
                  de Osorno, sino a lo largo de toda la región de los lagos. Además contamos
                  con grandes proveedores (<span className="text-green-800 font-bold">Dvp, Veratec, GU, Maco, 
                  Siegenia</span>, entre otros) que nos ayudan a llevar toda la calidad a su hogar.
                </p>
                <div className="ml-10 grid grid-flow-col grid-cols-2 3xl:m-20 justify-items-center items-center">
                  <div className="pt-3 xl:pt-10  ">
                    <img src={Siegenia} alt="" className="h-[30px] xl:h-[50px] "/>
                    <img src={Gu} alt=""className="h-[50px] xl:h-[100px] mt-14"/>
                  </div>
                  <div className="pt-3 xl:pt-10 ">
                     <img src={Veratec} alt="" className="h-[30px]  md:h-[50px] "/>
                    <img src={Dvp} alt="" className="h-[70px] md:h-[120px] mt-14 "/>
                  </div>
                </div>
              </div>
              </Fade>
            </div>
            <div className="">
              <Fade duration={1000} direction={"left"}>
                <img
                  src={Imagenes[3].imagen}
                  alt="fondo2"
                  className=" w-screen  md:w-[100%]   rounded "
                />
              </Fade>
            </div>
          </div>
        </section>
        <section id="section3" className="bg-white  mt-44 md:mt-[400px] lg:mt-0  h-screen">
          <div className="h-screen pt-14 px-4 grid lg:grid-cols-2 gap-2">
            <div className="order-2 xl:order-1">
              <Roll duration={1000}>
                <img
                  src={Imagenes[4].imagen}
                  alt="galery4"
                  className="w-[100%] rounded order-3"
                />
              </Roll>
            </div>
            <div className="order-1 xl:order-2">
              <Fade duration={1000} direction={"down"}>
                <h1
                  className="flex 
                                    justify-center 
                                    text-xl
                                    min-[281px]:text-2xl  
                                    sm:text-5xl
                                    font-mono 
                                    font-bold 
                                    text-green-800 
                                    align-center 
                                    text-bold "
                >
                  Nuestra Historia
                </h1>
              </Fade>
              <Fade duration={1000}>
                <p
                  className="mt-4
                            md:mx-4 
                            3xl:mt-10
                            text-sm 
                            lg:text-lg
                            xl:text-xl
                            3xl:text-3xl
                            text-gray-800 
                            font-mono
                            text-justify
                            tracking-wide "
                >
                  Carpyvent nació en 2017 de la mano de 
                  <span className="text-green-800 font-bold"> Cristóbal Catalán</span> y 
                  <span className="text-green-800 font-bold"> José Martínez</span> quienes habían 
                  trabajado en una importante empresa de vidrios y aluminios. En sus inicios, 
                  ambos amigos trabajaron arduamente en el patio de una casa sin maquinarias. 
                  En 2019, la empresa comenzó a florecer al ampliar su personal y trasladarse a 
                  un recinto más grande equipado con maquinaria y oficinas. Actualmente, la empresa 
                  cuenta con más de 10 empleados y dispone de espacios para recibir clientes y programas 
                  especializados para realizar cotizaciones. Además, cuentan con camiones para transportar y 
                  entregar ventanas a los hogares de los clientes.
                  
                </p>
              </Fade>
            </div>
          </div>
        </section>
        <section id="section4" className="bg-white h-screen mt-44 md:mt-[400px] lg:mt-0 ">
          <div className="pt-20 md:pt-10">
            <Fade duration={1000}>
              <h1 className="flex 
                                    justify-center 
                                    text-xl
                                    min-[281px]:text-2xl  
                                    sm:text-5xl
                                    font-mono 
                                    font-bold 
                                    text-green-800 
                                    align-center 
                                    text-bold ">
              Tipos de PVC
            </h1>
            </Fade>
            
            <Slide duration={1000}>
              <Card />
            </Slide>
            
          </div>
        </section>
        <section id="section5" className="bg-white h-screen mt-96 md:mt-[400px] lg:mt-0 ">
          <div className="pt-20 ">
            <Fade duration={1000} direction="down">
              <h1
                className="flex 
                                    justify-center 
                                    text-xl
                                    min-[281px]:text-2xl  
                                    sm:text-5xl
                                    font-mono 
                                    font-bold 
                                    text-green-800 
                                    align-center 
                                    text-bold "
              >
                Galeria de trabajo
              </h1>
            </Fade>

            <Slide className="pt-14" duration={1000}>
              <Slider />
            </Slide>
          </div>
        </section>
        <section id="section6" className=" h-full md:mt-[400px] lg:mt-0 bg-gradient-to-r from-green-900 to-green-600 ">
          <Contactanos/>
          <Footer/>
        </section>
      </div>
      <Navbar />
      
      
    </div>
  );
};

export default App;
